import request from '@/plugins/axios'

// 获取标签
export function getTagList(params) {
  return request({
    url: '/tagged/tags',
    method: 'get',
    params
  })
}

// 获取所有标签
export function getTagAll(params) {
  return request({
    url: '/tagged/tags:get_all',
    method: 'get',
    params
  })
}
// 获取消息标签
export function getMessageTagList(params) {
  return request({
    url: '/tagged/messages',
    method: 'get',
    params
  })
}
// 获取群组标签
export function getGroupTagList(params) {
  return request({
    url: '/tagged/groups',
    method: 'get',
    params
  })
}
// 获取用户标签
export function getUserTagList(params) {
  return request({
    url: '/tagged/users',
    method: 'get',
    params
  })
}
// 添加标签
export function addTagForAll(data) {
  return request({
    url: '/tagged/tags',
    method: 'post',
    data
  })
}

// 删除标签
export function delTag(id) {
  return request({
    url: `/tagged/tags/${id}`,
    method: 'delete'

  })
}
// 给用户添加标签
export function addTagForUser(id, data) {
  return request({
    url: `/tagged/users/${id}:batch_tag`,
    method: 'post',
    data
  })
}

// 给群组添加标签
export function addTagForGroup(id, data) {
  return request({
    url: `/tagged/groups/${id}:batch_tag`,
    method: 'post',
    data
  })
}

// 删除群组标签
export function delGroupTag(id) {
  return request({
    url: `/tagged/groups/${id}`,
    method: 'delete'

  })
}

// 删除用户标签
export function delUserTag(id) {
  return request({
    url: `/tagged/users/${id}`,
    method: 'delete'

  })
}

// 删除消息标签
export function delMessageTag(id) {
  return request({
    url: `/tagged/messages/${id}`,
    method: 'delete'

  })
}
