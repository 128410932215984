export const cacheMixin = {
  data() {
    return {
      activated_path: null
    }
  },
  computed: {
    tabList() {
      return this.$store.state.settings.tabs
    }
  },
  activated() {
    this.activated_path = this.$route.path
  },

  // beforeRouteLeave(to, from, next) {
  //   let tabList = this.$store.state.settings.tabs
  //   if (tabList && tabList.length > 0) {
  //     if (
  //       !tabList.some(item => {
  //         return item.path == this.activated_path
  //       })
  //     ) {
  //       var key =
  //         this.$vnode.key == null
  //           ? this.$vnode.componentOptions.Ctor.cid +
  //             (this.$vnode.componentOptions.tag
  //               ? `::${this.$vnode.componentOptions.tag}`
  //               : '')
  //           : this.$vnode.key

  //       for (let i in this.$vnode.parent.componentInstance.cache) {
  //         if (i == key) {
  //           delete this.$vnode.parent.componentInstance.cache[key]
  //         }
  //       }
  //       this.$vnode.parent.componentInstance.keys.forEach((item, i) => {
  //         if (item == key) {
  //           this.$vnode.parent.componentInstance.keys.splice(i, 1)
  //         }
  //       })
  //     }
  //   }
  //   next()
  // },
  deactivated() {
    if (this.tabList && this.tabList.length > 0) {
      if (
        !this.tabList.some(item => {
          return item.path == this.activated_path
        })
      ) {
        var key =
          this.$vnode.key == null
            ? this.$vnode.componentOptions.Ctor.cid +
              (this.$vnode.componentOptions.tag
                ? `::${this.$vnode.componentOptions.tag}`
                : '')
            : this.$vnode.key

        for (let i in this.$vnode.parent.componentInstance.cache) {
          if (i == key) {
            delete this.$vnode.parent.componentInstance.cache[key]
          }
        }
        this.$vnode.parent.componentInstance.keys.forEach((item, i) => {
          if (item == key) {
            this.$vnode.parent.componentInstance.keys.splice(i, 1)
          }
        })
      }
    }
  },
  methods: {}
}
